import { useStaticQuery, graphql } from "gatsby"
const useMarks = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiMarks(sort: { order: ASC, fields: published_at }) {
        nodes {
          name
          strapiId
        }
      }
    }
  `)
  return data.allStrapiMarks.nodes.map(mark => ({
    name: mark.name,
    strapiId: mark.strapiId
  }))
}

export default useMarks

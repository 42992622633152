import { useStaticQuery, graphql } from "gatsby"
const useTypes = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiType(sort: { order: ASC, fields: strapiId }) {
        nodes {
          name
          title
          strapiId
          image {
            publicURL
          }
        }
      }
    }
  `)
  return data.allStrapiType.nodes.map(type => ({
    image: type.image,
    strapiId: type.strapiId,
    name: type.name,
    title: type.title,
  }))
}

export default useTypes
